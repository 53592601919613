// This file is the entry point for the app's styles.
// Do not add styles directly in this file.
// Prefer adding styles to `GlobalStyles` (CSS-in-JS).

@import "../node_modules/ripple/styles/ripple.less";
@import "./placeholder.less";

.ripple-page.startup {
  background-color: #02828c;
  color: #1a1a1a;
}
